import { isAvailableOnline } from "modules/booking/pages/appointmentTypes/utils";

import {
  AppointmentTypeCombined,
  AppointmentTypeDto,
  AppointmentTypeTGDto,
  BookingStatus,
  ProviderAppointmentTypeCombined,
  ProviderAppointmentTypeDto
} from "../modules/booking/models";

/**Utility function to sort objects alphabetically by key */
export const alphabeticalSortPredicate =
  <T extends object>(key: keyof T) =>
  (a: T, b: T) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };

// Helper Functions
export const checkProviderBaseInterval = (
  appointmentType: AppointmentTypeDto,
  baseInterval: number
) => {
  // React can re-render the page with a undefined value before we navigate off to the provider appointment type's page.
  if (appointmentType === undefined) {
    return false;
  }

  const isNewDurationBaseIntervalWrong =
    appointmentType.newPatientDuration! % baseInterval !== 0 &&
    appointmentType.isAvailableNewPatients;

  const isExistingDurationBaseIntervalWrong =
    (appointmentType.minuteDuration! * 60) % baseInterval !== 0 &&
    appointmentType.isAvailableExistingPatients;

  return (
    (isNewDurationBaseIntervalWrong || isExistingDurationBaseIntervalWrong) &&
    appointmentType.customised
  );
};

export const checkLocationBaseInterval = (
  appointmentType: AppointmentTypeDto,
  baseInterval: number
) => {
  return (
    appointmentType.newPatientDuration! % baseInterval !== 0 &&
    appointmentType.isAvailableNewPatients === true
  );
};

export const checkLocationMultiAppt = (
  appointmentType: AppointmentTypeCombined
) => {
  return (
    (appointmentType.durationTG !== appointmentType.newPatientDuration! ||
      appointmentType.durationTG !== appointmentType.minuteDuration! * 60) &&
    appointmentType.groupBooking &&
    appointmentType.isAvailableNewPatients
  );
};

export function getCombinedAppointmentTypeData(
  appointmentTypes: AppointmentTypeDto[],
  appointmentTypesTG: AppointmentTypeTGDto[]
) {
  if (!appointmentTypes) return null;
  if (!appointmentTypesTG) return null;

  const mergedAppointtmentTypeData = appointmentTypes.map(
    currentAppointmentType => {
      const currentAppointmentTypeTG = appointmentTypesTG.find(
        type => type.id === currentAppointmentType.id
      );

      return {
        maxGroupSize: currentAppointmentTypeTG?.maxGroupSize,
        groupBooking: currentAppointmentTypeTG?.groupBooking,
        durationTG: currentAppointmentTypeTG?.duration,
        bookingStatus: isAvailableOnline(currentAppointmentType)
          ? BookingStatus.Online
          : BookingStatus.Offline,
        ...currentAppointmentType
      } as AppointmentTypeCombined;
    }
  );
  return mergedAppointtmentTypeData;
}

export function getCombinedProviderAppointmentTypeData(
  providerAppointmentTypes: ProviderAppointmentTypeDto[],
  appointmentTypesTG: AppointmentTypeTGDto[]
) {
  if (!providerAppointmentTypes) return null;
  if (!appointmentTypesTG) return null;

  const mergedAppointtmentTypeData = providerAppointmentTypes.map(
    currentAppointmentType => {
      const currentProviderAppointmentType = appointmentTypesTG.find(
        type => type.id === currentAppointmentType.appointmentTypeId
      );

      return {
        maxGroupSize: currentProviderAppointmentType?.maxGroupSize,
        groupBooking: currentProviderAppointmentType?.groupBooking,
        durationTG: currentProviderAppointmentType?.duration,
        ...currentAppointmentType
      } as ProviderAppointmentTypeCombined;
    }
  );
  return mergedAppointtmentTypeData;
}
